import * as Material from "@mui/material";

/**
 * Hook to get media queries using Material UI screen breakpoints.
 * @returns - the media queries
 */
export function useMediaQueries() {
	const theme = Material.useTheme();

	/**
	 * Small screen
	 */
	const isScreenSmAndUp: boolean = Material.useMediaQuery(theme.breakpoints.up("sm"));
	/**
	 * Medium screen
	 */
	const isScreenMedAndDown: boolean = Material.useMediaQuery(theme.breakpoints.down("md"));
	const isScreenMedAndUp: boolean = Material.useMediaQuery(theme.breakpoints.up("md"));
	/**
	 * Large screen
	 */
	const isScreenLgAndDown: boolean = Material.useMediaQuery(theme.breakpoints.down("lg"));
	const isScreenLgAndUp: boolean = Material.useMediaQuery(theme.breakpoints.up("lg"));
	/**
	 * Extra large screen
	 */
	const isScreenXlAndDown: boolean = Material.useMediaQuery(theme.breakpoints.down("xl"));

	return {
		isScreenSmAndUp,
		isScreenMedAndDown,
		isScreenMedAndUp,
		isScreenLgAndDown,
		isScreenLgAndUp,
		isScreenXlAndDown
	};
}
